<template>
  <div class="page-content">
    <div class="container-fluid">
      <Breadcrumb
        :pgTitle="$t('nav.videoConsultations')"
        :pgIcon="'bx bx-video-recording'"
        :refs="refs"
        :addNew="addNew"
      >
      </Breadcrumb>

      <DataTable
        :refs="refs"
        :colspan="9"
        :hasSearchFilter="true"
        :hasTimeFilter="true"
        :hasPeriodFilter="true"
        :hasDateFilter="true"
        :hasStatusApproved="true"
        :hasStatusFilter="true"
        :hasLecturerFilter="true"
        :hasHashId="true"
        :hasMultiSelect="false"
        :hasBulkAction="true"
        :HasExport="true"
        :hasShowEntries="true"
        :lblSearch="$t('app.search_by_name')"
        :fields="{
          id: 'id',
          username: 'username',
          period: 'period',
          price: 'price',
          time: 'time',
          date: 'date',
          paid: 'paid',
          status: 'status',
        }"
        :hasUser="true"
        :image="$t('view.user')"
        :image_style="'width: 40px;height: 40px;border-radius: 50%'"
        :price="$t('app.price')"
        :hasDate="true"
        :hasPaid="true"
        :hasTime="true"
        :hasPeriod="true"
        :hasPrice="true"
        :hasStatus="true"
        :hasNoEdit="true"
        :hasAccept="true"
        :hasDecline="true"
        :hasDelayed="true"
        :hasApproved="true"
        :hasActions="true"
        :hasNoDelete="true"
        :actionable="true"
        :sorDataTable="true"
        v-on:hasAddNewPermission="handlePermissoinChange"
      >
      </DataTable>
    </div>
  </div>
</template>

<script>
export default {
  name: "List",
  components: {
    Breadcrumb: () => import("@/components/Breadcrumb.vue"),
    DataTable: () => import("@/components/DataTable.vue"),
  },
  data() {
    return {
      //
      addNew: false,
      refs: "videoConsultations",
    };
  },
  watch: {
    //
  },
  mounted() {
    //
  },
  created() {
    //
  },
  methods: {
    handlePermissoinChange(event) {
      //this.addNew = event;
      console.log(event);
    },
  },
};
</script>
